import * as signatureActions from '@literax/modules/documents/store/signature/signature.actions';

import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  MergedSignRequest,
  WorkspaceSelectors,
  selectCurrentParticipant,
} from '@literax/modules/documents/store/workspace/workspace.selectors';
import { Observable, merge } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  selectEmailError,
  selectImageError,
  selectNameError,
  selectTokenErrors,
} from '@literax/modules/documents/store/signature/signature.selector';
import {
  selectEmailFreeError,
  selectFreeImageError,
  selectFreeTokenErrors,
  selectNameFreeError,
} from '@literax/store/free-signature/free-signature.selector';

import { ESignatureVerificationTypeId } from '@literax/enums/document.enum';
import { IAppState } from '@literax/store';
import { ICreateSignatureRequest } from '@literax/models/signature.model';
import {
  ILegalPerson
} from '@literax/models/participant.model';
import { WorkspaceActions } from '@literax/modules/documents/store/workspace/workspace.actions';
import { map } from 'rxjs/operators';

const HINT_SHOW_COUNT_KEY = 'hsck';

@UntilDestroy()
@Component({
  selector: 'literax-signature-form',
  templateUrl: './signature-form.component.html',
  styleUrls: ['./signature-form.component.scss'],
})
export class SignatureFormComponent implements OnInit, OnDestroy {
  @Input() signatureBodyRequest: ICreateSignatureRequest;
  @Input() documentId: number;
  @Input() tokenData: string;
  @Input() nameDocument: string;
  @Input() typeSignatureSession: boolean;
  @Input() plusSignature = false;
  @Input() isToValidate = false;
  showCompanyAndRole = false;
  signatureForm: FormGroup;
  permittedFiles = ['.pdf', '.jpg', '.png'];
  tokenErrorUser$ = this.store.pipe(
    untilDestroyed(this),
    select(selectTokenErrors)
  );
  imagesErrorUser$ = this.store.pipe(
    untilDestroyed(this),
    select(selectImageError)
  );
  imagesErrorFree$ = this.store.pipe(
    untilDestroyed(this),
    select(selectFreeImageError)
  );
  tokenErrorFree$ = this.store.pipe(
    untilDestroyed(this),
    select(selectFreeTokenErrors)
  );
  nameError$ = this.store.pipe(untilDestroyed(this), select(selectNameError));
  emailError$ = this.store.pipe(untilDestroyed(this), select(selectEmailError));
  nameFreError$ = this.store.pipe(
    untilDestroyed(this),
    select(selectNameFreeError)
  );
  emailFreeError$ = this.store.pipe(
    untilDestroyed(this),
    select(selectEmailFreeError)
  );
  tokenErrors$ = merge(this.tokenErrorUser$, this.tokenErrorFree$);
  imagesErrors$ = merge(this.imagesErrorUser$, this.imagesErrorFree$);
  namesErrors$ = merge(this.nameError$, this.nameFreError$);
  emailsErros$ = merge(this.emailError$, this.emailFreeError$);

  @Output() cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() disableSignButton: EventEmitter<boolean> =
    new EventEmitter<boolean>(true);
  @Input() makeSignature: Observable<ICreateSignatureRequest>;

  currentParticipant$ = this.store.pipe(
    untilDestroyed(this),
    select(selectCurrentParticipant)
  );
  signerId: number;

  signatureVerificationType$: Observable<ESignatureVerificationTypeId> =
    this.store.pipe(
      untilDestroyed(this),
      select(WorkspaceSelectors.selectSignatureVerificationType)
    );
  signatureVerificationType: ESignatureVerificationTypeId;
  eSignatureVerificationTypeId = ESignatureVerificationTypeId;
  passwordVisible = false;
  showHint = false;
  verificationTokenLabel: Observable<string>;

  smsCounter = 15;
  smsTimer: any;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<IAppState>,
    private actions$: Actions
  ) {
    this.createForm();
  }

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.setCompanies();
    if (this.plusSignature) {
      (this.signatureForm.get('electronicSignature') as FormGroup).addControl(
        'twoFactorConfirmationToken',
        new FormControl('', Validators.required)
      );
      this.signatureVerificationType$.subscribe((signatureVerificationType) => {
        this.signatureVerificationType = signatureVerificationType;

        if (signatureVerificationType === ESignatureVerificationTypeId.TOKEN) {
          this.store.dispatch(WorkspaceActions.getVerificationTypes());
          this.verificationTokenLabel = this.store.pipe(
            untilDestroyed(this),
            select((state) => state.workspace.signatureVerificationTypes),
            map(
              (verificationType) =>
                verificationType.find(
                  (vType) => vType.id === signatureVerificationType
                )?.name
            )
          );
        }

        if (signatureVerificationType === ESignatureVerificationTypeId.EMAIL) {
          this.validateHint();
        }
      });
      this.actions$
        .pipe(
          untilDestroyed(this),
          ofType(
            signatureActions.twoFactorTokenError,
            signatureActions.twoFactorToken
          )
        )
        .subscribe((action) => {
          const control: AbstractControl = (
            this.signatureForm.get('electronicSignature') as FormGroup
          ).get('twoFactorConfirmationToken');
          if (
            this.signatureVerificationType ===
              ESignatureVerificationTypeId.SMS &&
            action.type ===
              signatureActions.ESignatureActions.twoFactorTokenError
          ) {
            control.setErrors({
              smsError: {
                failed: true,
                message: action.payload.message,
              },
            });
            clearInterval(this.smsTimer);
            this.smsCounter = 15;
          }
        });
    }
    this.makeSignature.pipe(untilDestroyed(this)).subscribe((data) => {
      this.onSubmit(data);
    });
  }

  validateHint() {
    const hintsCount = localStorage.getItem(HINT_SHOW_COUNT_KEY);
    if (hintsCount === null) {
      localStorage.setItem(HINT_SHOW_COUNT_KEY, JSON.stringify({ count: 1 }));
    } else {
      const count = JSON.parse(hintsCount).count + 1;
      localStorage.setItem(HINT_SHOW_COUNT_KEY, JSON.stringify({ count }));
      if (count >= 10) {
        this.showHint = true;
      }
    }
  }

  createForm() {
    this.signatureForm = this.formBuilder.group({
      electronicSignature: this.formBuilder.group({
        email: [null, [Validators.required, Validators.email]],
        name: [null, [Validators.required]],
        signatureImage: [null, [Validators.required]],
        moralInfo: this.formBuilder.array([]),
      }),
    });
    this.signatureForm.statusChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.disableSignButton.emit(!this.signatureForm.valid);
      });
  }

  setCompanies() {
    const items = this.signatureForm
      .get('electronicSignature')
      .get('moralInfo') as FormArray;

    this.currentParticipant$
      .pipe(untilDestroyed(this))
      .subscribe((currentParticipant: MergedSignRequest) => {
        this.signerId = currentParticipant.signRequestId;
        if (this.instanceOfLegalPerson(currentParticipant)) {
          this.showCompanyAndRole = true;
          const form = this.buildSignRequests();
          form.get('companyName').setValidators(Validators.required);
          form.get('companyName').updateValueAndValidity();
          form.get('role').setValidators(Validators.required);
          form.get('role').updateValueAndValidity();
          items.push(form);
        } else {
          this.showCompanyAndRole = false;
          items.clear();
        }
      });
    this.signatureForm.patchValue(this.signatureBodyRequest);
  }

  instanceOfLegalPerson(data: any): data is ILegalPerson {
    return 'companyName' in data;
  }

  buildSignRequests(): FormGroup {
    return this.formBuilder.group({
      companyName: [null],
      role: [null],
    });
  }

  onSubmit(data: ICreateSignatureRequest) {
    const mergeSignature = {
      ...this.signatureBodyRequest.electronicSignature,
      ...this.signatureForm.value.electronicSignature,
    };
    const signData = { ...mergeSignature };

    const haveId = signData?.idImage ? { idImage: signData.idImage } : {};
    const haveIdImageFront = signData?.idImageFront ? { idImageFront: signData.idImageFront } : {};
    const haveIdImageBack = signData?.idImageBack ? { idImageBack: signData.idImageBack } : {};
    const haveLatitude = signData?.latitude ? { latitude: signData.latitude } : {};
    const haveLongitude = signData?.longitude ? { longitude: signData.longitude } : {};

    const SignatureBodyRequest: ICreateSignatureRequest = {
      electronicSignature: {
        name: signData.name,
        email: signData.email,
        twoFactorConfirmationToken: signData.twoFactorConfirmationToken,
        signatureImage: signData.signatureImage,
        ...haveId,
        ...haveIdImageFront,
        ...haveIdImageBack,
        ...haveLatitude,
        ...haveLongitude,
        companyName: signData.moralInfo[0]?.companyName,
        role: signData.moralInfo[0]?.role,
      },
    };

    if (this.typeSignatureSession) {
      this.store.dispatch(
        signatureActions.registerSignature({
          payload: {
            documentId: this.documentId,
            signRequestId: this.signerId,
            signatureData: SignatureBodyRequest,
          },
        })
      );
    } else {
      this.store.dispatch(
        signatureActions.registerSignature({
          payload: {
            documentId: this.documentId,
            signRequestId: this.signerId,
            signatureData: SignatureBodyRequest,
            token: this.tokenData,
          },
        })
      );
    }
  }

  saveSignature(event: string) {
    const field = this.signatureForm
      .get('electronicSignature')
      .get('signatureImage');
    field.setValue(event);
    field.updateValueAndValidity();
  }

  preventDefault(e) {
    e.preventDefault();
  }

  get moralInfo(): FormArray {
    return this.signatureForm
      .get('electronicSignature')
      .get('moralInfo') as FormArray;
  }

  twoFactorToken() {
    if (this.signatureVerificationType === ESignatureVerificationTypeId.SMS) {
      this.store.dispatch(
        signatureActions.twoFactorToken({
          documentId: this.documentId,
          signRequestId: this.signerId,
          token: this.typeSignatureSession ? null : this.tokenData,
        })
      );
      this.smsTimer = setInterval(() => {
        this.smsCounter--;
        if (this.smsCounter === 0) {
          clearInterval(this.smsTimer);
          this.smsCounter = 15;
        }
      }, 1000);
    } else {
      this.store.dispatch(
        signatureActions.twoFactorToken({
          documentId: this.documentId,
          signRequestId: this.signerId,
          token: this.typeSignatureSession ? null : this.tokenData,
        })
      );
    }
  }
}
