import * as documentActions from '@literax/modules/documents/store/document/document.actions';
import * as usersActions from '@literax/components/configurations/users/states/users.actions';

import {
  EDocumentApprovalActions,
  EDocumentCommonActions,
  EDocumentRequestActions,
  EDocumentReviewActions,
  EDocumentSignatureActions,
  EDocumentStatus,
  EFlowName,
  ESigningMethod,
  ETransactionType,
} from '@literax/enums/document.enum';
import {
  ILegalPerson,
  INaturalPerson,
} from '@literax/models/participant.model';
import { ModalOptions, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Store, select } from '@ngrx/store';
import { Subscription, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ActionModalComponent } from '@literax/modules/shared/confirm-modal/confirm-modal.component';
import { AddModalDateComponent } from '@literax/components/platform/workspace/generate/add-modal-date/add-modal-date.component';
import { AssignmentDialogComponent } from '@literax/modules/shared/assignment-dialog/assignment-dialog.component';
import { AttachmentActions } from '@literax/modules/documents/store/workspace/actions/attachment.actions';
import { B2CAuthService } from '@literax/b2c-auth/b2c-auth.service';
import { ConfirmDialogApprovalComponent } from '@literax/modules/shared/confirm-dialog-approval/confirm-dialog-approval.component';
import { EKindAttachment } from '@literax/enums/attachment.enum';
import { EWorkingMode } from '@literax/modules/documents/store/workspace/workspace.state';
import { IAppState } from '@literax/store';
import { ICreateSignatureRequest } from '@literax/models/signature.model';
import { IDocumentResponse } from '@literax/models/document.model';
import { IProcessRequestBody } from '../../../../../models/document.model';
import { IProfile } from '@literax/components/configurations/profiles/models/profiles';
import { IViewingAttachment } from '@literax/models/attachment.model';
import { IdentityValidationModalComponent } from '@literax/modules/shared/identity-validation-modal/identity-validation-modal.component';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { SignModalComponent } from '@literax/components/platform/sign-modal/sign-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { WorkspaceActions } from '@literax/modules/documents/store/workspace/workspace.actions';
import { WorkspaceSelectors } from '@literax/modules/documents/store/workspace/workspace.selectors';
import { environment } from '@environments/environment';
import jwt_decode from 'jwt-decode';
import { selectDepartmentReceivers } from '../../../../../components/configurations/users/states/users.selector';
import { untilDestroyed } from '@ngneat/until-destroy';
import * as signalR from '@microsoft/signalr';
import { LoadingService } from '@literax/services/loading/loading.service';
import { ModalSendSignedDocComponent } from '@literax/modules/shared/modal-send-signed-doc/modal-send-signed-doc.component';
import { ParticipantService } from '../../../../../services/participant/participant.service';


export const checkIfDocxAttachmentHasPdf = (attachmentId: number): boolean => {
  const attachmentStorage = localStorage.getItem('attachments');
  if (attachmentStorage !== null) {
    const storedDocxAttachments = JSON.parse(attachmentStorage);
    if (storedDocxAttachments && attachmentId in storedDocxAttachments) {
      return storedDocxAttachments[attachmentId].hasPdf;
    } else {
      cacheDocxAttachmentState(attachmentId, false);
      return false;
    }
  } else {
    cacheDocxAttachmentState(attachmentId, false);
    return false;
  }
};

type IAnyUser = Partial<IProfile> &
  Partial<INaturalPerson> &
  Partial<ILegalPerson>;

export const cacheDocxAttachmentState = (
  attachmentId: number,
  state?: boolean,
  callback?: () => void
): void => {
  const attachmentStorage = localStorage.getItem('attachments');
  const storedDocxAttachments = JSON.parse(attachmentStorage);
  const updatedDocxAttachments = {
    ...storedDocxAttachments,
    ...{ [attachmentId]: { hasPdf: state } },
  };
  localStorage.setItem('attachments', JSON.stringify(updatedDocxAttachments));
  if (callback) {
    callback();
  }
};

export abstract class WorkspaceButtonBar {
  isAuthor: boolean;
  isParticipant: boolean;
  isGuest: boolean;
  hasQuote: boolean;
  isRejectedOrExpired: boolean;
  isConfigScreen = false;
  isViewingDocxAttachment = false;
  isPromissoryNoteFlow = false;
  hasPdfVersion = false;
  flow: EFlowName;
  status: EDocumentStatus;
  signingMethodTypes = ESigningMethod;
  actions = {
    signature: EDocumentSignatureActions,
    approval: EDocumentApprovalActions,
    review: EDocumentReviewActions,
    request: EDocumentRequestActions,
    application: EDocumentCommonActions,
  };
  statusTypes = EDocumentStatus;
  flowTypes = EFlowName;
  formBuilderInvalid: boolean;
  flowWithTemplate = false;
  haveParticipants = false;
  document: IDocumentResponse;
  user: IAnyUser;
  signingMethod: ESigningMethod;
  participants: Array<INaturalPerson | ILegalPerson> = [];
  private routerEventsSubscription: Subscription;
  guestToken: string;
  // dependencias de la clase
  store: Store<IAppState>;
  modalService: NzModalService;
  router: Router;
  translateService: TranslateService;
  b2cAuthService: B2CAuthService;
  msalService: MsalService;
  currentParticipant: INaturalPerson | ILegalPerson;
  isRequestReceiver: boolean;
  loading: LoadingService;
  participantService : ParticipantService;

  confirmableActions: string[] = [
    EDocumentSignatureActions.SEND_SIGN,
    EDocumentSignatureActions.SEND_COLLECTION,
    EDocumentReviewActions.SEND_REVIEW,
    EDocumentApprovalActions.SEND_APPROVAL,
    EDocumentApprovalActions.TO_SIGNATURE,
    EDocumentRequestActions.TO_SEND,
    EDocumentCommonActions.CANCEL,
    EDocumentCommonActions.DELETE,
    EDocumentCommonActions.REJECT,
    EDocumentCommonActions.TO_DRAFT,
    EDocumentRequestActions.TO_REVIEW,
    EDocumentRequestActions.TO_APPROVAL,
    EDocumentRequestActions.TO_SIGNATURE
  ];

  applicationActions: string[] = [
    EDocumentCommonActions.BACK_TO_WORKSPACE,
    EDocumentCommonActions.VIEW_SIGNATURES,
    EDocumentCommonActions.DOWNLOAD,
    EDocumentCommonActions.SAVE,
    EDocumentCommonActions.PROMISSORY_NOTE_EDIT,
    EDocumentRequestActions.ASSIGN,
    EDocumentReviewActions.REVIEW_RELOAD
  ];

  attachmentReaders: boolean;
  attachment: IViewingAttachment;

  workingMode: EWorkingMode;
  isDesktopDevice: boolean;
  socket: signalR.HubConnection;
  subscriptions: Subscription[] = [];
  isSaveReview = true;
  unattendedLink: string;
  latitude?:string;
  longitude?:string;

  constructor(
    {
      store,
      router,
      modalService,
      translateService,
      options,
      b2cAuthService,
      msalService,
      loading,
      participantService
  }: {
      store: Store<IAppState>;
      router?: Router;
      modalService?: NzModalService;
      translateService?: TranslateService;
      options?: Record<string, unknown>;
      b2cAuthService: B2CAuthService;
      msalService: MsalService;
      loading?: LoadingService;
      participantService : ParticipantService,
  }){
      this.router = router;
      this.store = store;
      this.modalService = modalService;
      this.translateService = translateService;
      this.b2cAuthService = b2cAuthService;
      this.msalService = msalService;
      this.loading = loading;
      this.participantService = participantService;
    combineLatest([
        store.select((state) => {
          return {
            user: state.auth?.currentUser,
            flowName: state.workspace?.document?.flowName,
            document: state.workspace?.document,
            recordState: state.workspace?.formBuilderRequiredFieldsFilled,
            mainAttachment: state.workspace?.mainAttachment,
            attachment: state.workspace?.attachment,
            isPromissoryNoteFlow: state.workspace?.isPromissoryNoteFlow,
            guestToken: state.workspace?.guestToken,
            isGuest: state.workspace?.workingModeType === EWorkingMode.GUEST,
            workingMode: state.workspace.workingModeType,
            isDesktop: state.core.isDesktop,
          };
        }),
        store.select(WorkspaceSelectors.selectDocumentParticipants),
        store.select(WorkspaceSelectors.selectAttachmentsReaded),
      ])
        .pipe(
          filter(
            ([data, participants, attachmentReaders]) =>
              data.document !== null && attachmentReaders !== null
          ),
          map(([state, participants, attachmentReaders]) => ({
            ...state,
            participants,
            attachmentReaders,
          }))
        )
        .subscribe((data) => {
          this.isDesktopDevice = data.isDesktop;
          this.guestToken = data?.guestToken;
          this.isGuest = data.isGuest;
          this.workingMode = data?.workingMode;
          this.isPromissoryNoteFlow = data?.isPromissoryNoteFlow;
          this.user = [
            ...data.document?.signRequests?.legalPersons,
            ...data.document?.signRequests?.naturalPersons,
            ...data.document?.signRequests?.naturalPersons.filter(x => x.substitute !== null).map(x => x.substitute)
          ].find((participant) => participant.isOneSelf);
          this.document = data?.document;
          this.attachment = data?.attachment;
          this.isViewingDocxAttachment =
            data.attachment.kind === EKindAttachment.DOCX;
          this.formBuilderInvalid = !data.recordState;
          this.flow = data?.document?.flowName;
          this.status = data?.document?.status?.key;
          this.haveParticipants = data.participants.participantsCount > 0;
          this.participants = [
            ...data.participants.legalPersons,
            ...data.participants.naturalPersons,
            ...data.document?.signRequests?.naturalPersons.filter(x => x.substitute !== null).map(x => x.substitute as INaturalPerson)
          ];
          this.signingMethod =
            data?.document?.signingConfiguration?.signingMethod;
          this.flowWithTemplate = [
            EKindAttachment.TEMPLATE,
            EKindAttachment.TICKET,
            EKindAttachment.FORM,
          ].includes(data?.mainAttachment.kind);
          this.validateAuthor(data.user, data.document);
          this.attachmentReaders = data.attachmentReaders;
          this.attachment = data?.attachment;
          this.hasPdfVersion = checkIfDocxAttachmentHasPdf(data?.attachment?.id);
  
      });

      const currentUrlPath = window.location.pathname.split('/');
      this.isConfigScreen = currentUrlPath.includes('configuration');
    }

  validateAuthor(user: IProfile, document: IDocumentResponse): void {
    if(!this.isGuest){
      const tokenSilentRequest = {
        scopes: environment.b2cConfig.scopes,
        account: this.b2cAuthService.getUserInfo(),
      };
      const token$ = this.msalService.acquireTokenSilent(tokenSilentRequest);
      token$
        .pipe(
          untilDestroyed(this),
          filter((tokenResponse) => tokenResponse !== null)
        )
        .subscribe((b2cToken) => {
          const TokenDecoded = jwt_decode(b2cToken.accessToken);
          const [email] = [TokenDecoded['email']];
          this.isAuthor = document?.author?.userName === email;
        });
    }
    
    this.isRequestReceiver =
      this.user?.signatureRole?.signatoryRoleName === 'generator';
    if (this.guestToken) {
      this.isAuthor = false;
    }
    this.isParticipant = document?.nextActions.some((action) =>
      ['sign', 'to_approved', 'to_reviewed', 'send_sign'].includes(action)
    );
    this.hasQuote = [
      ...document.signRequests.legalPersons,
      ...document.signRequests.naturalPersons,
    ].some((participant) => participant.quote !== null);
  }

  // maneja las acciones emitidas por los botones
  action(action: string): void {
    const warnings: string[] = [
      EDocumentCommonActions.DELETE,
      EDocumentCommonActions.REJECT,
      EDocumentCommonActions.CANCEL,
    ];
    const nzOkDanger = warnings.includes(action) ? true : false;
    if (this.confirmableActions.includes(action)) {
      this.translateService
        .get(`MODULES.WORKSPACE.MODALS.CONFIRMS.${action.toUpperCase()}`, {
          flow:
            this.flow === 'request'
              ? this.translateService
                  .instant(`MODULES.WORKSPACE.FLOWS.GENERATION`)
                  .toLowerCase()
              : this.translateService
                  .instant(`MODULES.WORKSPACE.FLOWS.${this.flow.toUpperCase()}`)
                  .toLowerCase(),
        })
        .subscribe((confirmTitle) => {
          this.modalService.confirm(
            {
              nzCentered: true,
              nzTitle: confirmTitle,
              nzContent: this.document?.name,
              nzOnOk: () => this.dispatchAction(action),
              nzOkDanger,
            },
            warnings.includes(action) ? 'warning' : 'info'
          );
        });
    } else if (this.applicationActions.includes(action)) {
      switch (action) {
        case EDocumentCommonActions.BACK_TO_WORKSPACE:
          this.router.navigate(
            [
              'platform',
              'documents',
              ETransactionType[this.document.transactionType].toLowerCase(),
              this.document?.id,
            ],
            { replaceUrl: true }
          );
          break;

        case EDocumentCommonActions.VIEW_SIGNATURES:
          this.router.navigate([
            'platform',
            'documents',
            ETransactionType[this.document.transactionType].toLowerCase(),
            this.document?.id,
            'signatures',
          ]);
          break;

        case EDocumentCommonActions.PROMISSORY_NOTE_EDIT:
          this.router.navigate([
            'platform',
            'promissory-notes',
            'edit',
            this.document?.id,
          ]);
          break;

        case EDocumentCommonActions.DOWNLOAD:
          this.store.dispatch(WorkspaceActions.download());
          break;

        case EDocumentCommonActions.SAVE:
          setTimeout(() => {
            this.store.dispatch(AttachmentActions.transformDocxToPDF());  
          }, 1000);
          
          break;
        case EDocumentReviewActions.REVIEW_RELOAD:
          //este case es para utilizar alda dar clic en guardar y el socket 
          //esta en espera a que los participantes concluyan la revision
          setTimeout(() => {
            this.isSaveReview = false;
          this.store.dispatch(AttachmentActions.transformDocxToPDF());  
          }, 5000); 
          break;
        case EDocumentRequestActions.ASSIGN:
          this.assignRequest();
          break;
      }
    } else {
      switch (action) {
        case EDocumentSignatureActions.SIGN:
          this.signingMethod === ESigningMethod.DIGITAL_SIGNATURE
            ? this.digitalSignature(this.document)
            : this.electronicSignature(this.document);
          break;
        case EDocumentApprovalActions.TO_APPROVED:
          {
            let payload: IProcessRequestBody = { action };

            if (this.isGuest) {
              payload = { ...payload, token: this.guestToken };
            }

            this.store.dispatch(WorkspaceActions.handleAction({ payload }));
          }
          break;
        case EDocumentRequestActions.TO_SEND:
          this.store.dispatch(
            WorkspaceActions.handleAction({
              payload: {
                action,
              },
            })
          );
          break;
        case EDocumentRequestActions.TO_GENERATED:
          this.store.dispatch(
            WorkspaceActions.handleAction({
              payload: {
                action,
              },
            })
          );
          break;
        case EDocumentReviewActions.TO_REVIEWED:
          {
            let payload: IProcessRequestBody = { action };

            if (this.isGuest) {
              payload = { ...payload, token: this.guestToken };
            }
            this.store.dispatch(
              WorkspaceActions.handleAction({
                payload,
              })
            );
          }
          break;
        case EDocumentRequestActions.ACCEPT:
          this.displayDeliveryDateModal(action);
          break;
      }
    }
  }

  assignRequest() {
    this.store.dispatch(usersActions.getReceivers({ excludeOneself: true }));
    const departmentReceivers$ = this.store.pipe(
      select(selectDepartmentReceivers)
    );

    this.modalService.create({
      nzWidth: 400,
      nzTitle: this.translateService.instant(
        'DOCUMENT-PREVIEW.WORKSPACE.UI.MODAL_TITLES.REQUEST_ACCEPT'
      ),
      nzContent: AssignmentDialogComponent,
      nzComponentParams: {
        data: {
          departmentReceivers$,
          delegate: (request) =>
            this.store.dispatch(
              documentActions.DocumentCreateAssigment({
                payload: {
                  documentId: this.document?.id,
                  receiver: { userId: request.userId },
                  userName: request.userName,
                },
              })
            ),
        },
      },
    });
  }

  displayDeliveryDateModal(action: string) {
    this.modalService
      .create<AddModalDateComponent, { requestInfo: { deliveryDate: number } }>(
        {
          nzTitle: this.translateService.instant(
            'DOCUMENT-PREVIEW.WORKSPACE.UI.MODAL_TITLES.REQUEST_ACCEPT'
          ),
          nzContent: AddModalDateComponent,
          nzCentered: true,
        }
      )
      .afterClose.subscribe((result) => {
        if (result && 'requestInfo' in result) {
          this.store.dispatch(
            WorkspaceActions.handleAction({
              payload: {
                action,
                ...result,
              },
            })
          );
        }
      });
  }

  electronicSignature(document: IDocumentResponse) {
    let modalStyle = {};
    let centered = true;
    if (this.isDesktopDevice) {
      centered = false;
      modalStyle = {
        position: 'absolute',
        top: '30px',
        right: '30px',
      };
    }
    if (!this.isDesktopDevice) {
      modalStyle = {
        position: 'absolute',
        top: '30px',
        left: '0px',
        right: '0px',
      };
    }
    let signDialog: NzModalRef<IdentityValidationModalComponent>;
    const signatureBody: ICreateSignatureRequest = {
      electronicSignature: {
        name: this.makeFullName(),
        email: this.user?.email,
        signatureImage: '',
      },
    };
   
    if (this.document.signingConfiguration?.biometrics !== null && this.user?.signRequestValidationTypeId === 4) {

      if (this.document.signingConfiguration?.requiredNavigatorGeolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.latitude =
              position.coords.latitude.toString();
            this.longitude =
              position.coords.longitude.toString();

            
      this.participantService.getRequestBiometricValidationLink(this.document.id, this.user.signRequestId, this.guestToken).subscribe((response) => {
        
        this.unattendedLink = response.biometricVerificationLink;
        
        this.translateService.get('FORM.SIGNATURE.TITLE').subscribe((nzTitle) => {
          signDialog = this.modalService.create({
            nzTitle,
            nzContent: IdentityValidationModalComponent,
            nzCentered: centered,
            nzKeyboard: false,
            nzMaskClosable: false,
            nzWidth: this.isDesktopDevice && this.user?.signRequestValidationTypeId === 4? '960px':'480px',
            nzStyle: {
              ...modalStyle,
            },
            nzComponentParams: {
              signatureBodyRequest: signatureBody,
              faceMatch: document?.signingConfiguration?.facematch,
              signRequestValidationTypeId: this.user?.signRequestValidationTypeId,
              documentRequest: this.user?.idDocumentRequest,
              plusSignature:
                document?.signingConfiguration?.signingMethod ===
                ESigningMethod.ELECTRONIC_SIGNATURE_PLUS
                  ? true
                  : false,
              nameDocument: document.name,
              documentId: document.id,
              typeSignatureSession: !this.guestToken,
              tokenData: this.guestToken,
              signRquestId: this.user.signRequestId,
              unattendedLink: this.unattendedLink,
              latitude: this.latitude,
              longitude: this.longitude,
            },
          });
        });
      },(error) => {
        this.modalService.error({
          nzTitle: 'Error!',
          nzContent: error.message,
        });
      });


          },
          () => {
            this.modalService.error({
              nzTitle: 'Error!',
              nzContent: this.translateService.instant('IDENTITY_VALIDATION.MODALS.ERRORS.NAVIGATOR_GEOLOCATION_REQUIRED'),
            });
          }
        );
      }else{
        
      this.participantService.getRequestBiometricValidationLink(this.document.id, this.user.signRequestId, this.guestToken).subscribe((response) => {
        
        this.unattendedLink = response.biometricVerificationLink;
        
        this.translateService.get('FORM.SIGNATURE.TITLE').subscribe((nzTitle) => {
          signDialog = this.modalService.create({
            nzTitle,
            nzContent: IdentityValidationModalComponent,
            nzCentered: centered,
            nzKeyboard: false,
            nzMaskClosable: false,
            nzWidth: this.isDesktopDevice && this.user?.signRequestValidationTypeId === 4? '960px':'480px',
            nzStyle: {
              ...modalStyle,
            },
            nzComponentParams: {
              signatureBodyRequest: signatureBody,
              faceMatch: document?.signingConfiguration?.facematch,
              signRequestValidationTypeId: this.user?.signRequestValidationTypeId,
              documentRequest: this.user?.idDocumentRequest,
              plusSignature:
                document?.signingConfiguration?.signingMethod ===
                ESigningMethod.ELECTRONIC_SIGNATURE_PLUS
                  ? true
                  : false,
              nameDocument: document.name,
              documentId: document.id,
              typeSignatureSession: !this.guestToken,
              tokenData: this.guestToken,
              signRquestId: this.user.signRequestId,
              unattendedLink: this.unattendedLink,
              latitude: this.latitude,
              longitude: this.longitude,
            },
          });
        });
      },(error) => {
        this.modalService.error({
          nzTitle: 'Error!',
          nzContent: error.message,
        });
      });
      }



    } else {
      this.translateService.get('FORM.SIGNATURE.TITLE').subscribe((nzTitle) => {
        signDialog = this.modalService.create({
          nzTitle,
          nzContent: IdentityValidationModalComponent,
          nzCentered: centered,
          nzKeyboard: false,
          nzMaskClosable: false,
          nzWidth: this.isDesktopDevice && this.user?.signRequestValidationTypeId === 4? '960px':'480px',
          nzStyle: {
            ...modalStyle,
          },
          nzComponentParams: {
            signatureBodyRequest: signatureBody,
            faceMatch: document?.signingConfiguration?.facematch,
            signRequestValidationTypeId: this.user?.signRequestValidationTypeId,
            documentRequest: this.user?.idDocumentRequest,
            plusSignature:
              document?.signingConfiguration?.signingMethod ===
              ESigningMethod.ELECTRONIC_SIGNATURE_PLUS
                ? true
                : false,
            nameDocument: document.name,
            documentId: document.id,
            typeSignatureSession: !this.guestToken,
            tokenData: this.guestToken,
            signRquestId: this.user.signRequestId,
            unattendedLink: this.unattendedLink,
          },
        });
      });
    }

  }

  digitalSignature(document: IDocumentResponse) {
    this.translateService
      .get('DOCUMENT-PREVIEW.SIGN.TITLE')
      .subscribe((nzTitle) => {
        this.modalService.create({
          nzMask: false,
          nzTitle,
          nzContent: SignModalComponent,
          nzWidth: 524,
          nzComponentParams: {
            data: {
              showCancel: true,
              document,
              currentCertName: this.user?.cert,
              isRequiredCertificated:
                document?.signingConfiguration?.requiredLegalCertificate,
            },
          },
          nzOnCancel: () => {
            this.modalService.closeAll();
          },
        });
      });
  }

  private dispatchAction(action: string) {
    let modalOptions: ModalOptions<ActionModalComponent> = {
      nzMask: false,
      nzWidth: 500,
      nzCentered: true,
      nzOnOk: this.afterSuccess,
    };

    switch (action) {
      case EDocumentCommonActions.REJECT:
        modalOptions = {
          ...modalOptions,
          nzContent: ActionModalComponent,
          nzTitle: this.translateService.instant(
            `MODULES.WORKSPACE.MODALS.TITLES.${action.toUpperCase()}`
          ),
          nzComponentParams: {
            showRejectTextarea: true,
          },
          nzOnOk: ({ rejectForm }) => {
            if (rejectForm.valid) {
              this.store.dispatch(
                WorkspaceActions.handleAction({
                  payload: {
                    action,
                    description: rejectForm.get('reason').value,
                    token: this.guestToken,
                  },
                })
              );
            }
          },
        };
        break;

      case EDocumentCommonActions.CANCEL:
        this.store.dispatch(
          WorkspaceActions.handleAction({ payload: { action } })
        );
        return;
      case EDocumentSignatureActions.SEND_SIGN:
        if (this.attachment?.kind === EKindAttachment.DOCX) {
          this.store.dispatch(
            WorkspaceActions.handleAction({ payload: { action } })
          );
          this.store.dispatch(
            WorkspaceActions.setOnlyOfficeEditorState({
              payload: {
                requestOnlyOfficeConfig: false,
                requestPdfVersion: true,
              },
            })
          );
        } else {
          this.store.dispatch(
            WorkspaceActions.handleAction({
              payload: { action, token: this.guestToken },
            })
          );
        }
        if (!this.guestToken) {
          this.router.navigate(
            [
              'platform',
              'documents',
              ETransactionType[this.document.transactionType].toLowerCase(),
              this.document?.id,
            ],
            { replaceUrl: true }
          );
        } else {
          this.router.navigate(['sign', this.guestToken], { replaceUrl: true });
        }

        return;
      case EDocumentApprovalActions.TO_SIGNATURE:
        if (this.document?.transactionType === ETransactionType.APPROVAL) {
          this.translateService
            .get('WORKSPACE.FOOTER.CONFIRM_APPROVAL.TITLE_MODAL')
            .subscribe((nzTitle) => {
              const modalConfirmApproval = this.modalService.create({
                nzMask: false,
                nzWidth: 500,
                nzTitle,
                nzCentered: true,
                nzContent: ConfirmDialogApprovalComponent,
              });
              modalConfirmApproval.componentInstance.optionalConf.subscribe(
                (configurations) => {
                  if (
                    configurations['constancy'] &&
                    configurations['participants'] === false
                  ) {
                    this.store.dispatch(
                      WorkspaceActions.handleAction({
                        payload: {
                          action,
                          transferInfo: {
                            approvalConstancy: configurations['constancy'],
                            signRequestIds: null,
                          },
                        },
                      })
                    );
                  } else {
                    this.router.navigate(
                      [
                        'platform',
                        'documents',
                        'approval',
                        this.document?.id,
                        'approvers-to-signers',
                      ],
                      { queryParams: { ...configurations, action } }
                    );
                  }
                }
              );
            });
        } else {

          setTimeout(() => {
            this.store.dispatch(
              WorkspaceActions.handleAction({
                payload: {
                  action,
                },
              })
            );
          }, 7000);
          
        }
        return;
      case EDocumentSignatureActions.SEND_COLLECTION:
        if (!this.guestToken) {
          this.router.navigate(
            [
              'platform',
              'documents',
              ETransactionType[this.document.transactionType].toLowerCase(),
              this.document?.id,
            ],
            { replaceUrl: true }
          );
        } else {
          this.router.navigate(['sign', this.guestToken], { replaceUrl: true });
        }

        this.store.dispatch(
          WorkspaceActions.handleAction({ payload: { action } })
        );

        return;
      default:
        this.store.dispatch(
          WorkspaceActions.handleAction({ payload: { action } })
        );
        return;
    }

    this.modalService.create<ActionModalComponent>(modalOptions);
  }

  sendSignedDocument() {
    const document = this.document;
    combineLatest(
    this.translateService
      .get('MODULES.WORKSPACE.MODALS.TITLES.SEND_SIGNED_DOCUMENT'),
      this.translateService
      .get('MODULES.WORKSPACE.MODALS.MESSAGES.SEND_SIGNED_DOCUMENT.FORM.SEND_BUTTON'))
      .subscribe(([nzTitle, nzOktext]) => {
        this.modalService.create({
          nzMask: false,
          nzTitle,
          nzContent: ModalSendSignedDocComponent,
          nzWidth: 400,
          nzComponentParams: {
            data: {
              document
            },
          },
          nzOnCancel: () => {
            this.modalService.closeAll();
          },
          nzOnOk: ({ selectedCollaborators }) => {
              this.store.dispatch(
                WorkspaceActions.sendEmailNotification({
                  payload: {
                    notificationPayload: {
                      kind : 0,
                      signRequestIds: selectedCollaborators 
                    }
                  },
                })
              );
          },
          nzOkText: nzOktext
        });
      });
  }


  private afterSuccess(data?: any) {}

  destroy() {}

  private makeFullName() {
    return this.user.name;
  }
}
