export enum SetClientStrategy {
  FIRST_LOGIN = 'first_login',
  CLIENT_SELECTED = 'client_selected',
}

export enum EClientKind {
  NATURAL_PERSON = 'natural_person',
  LEGAL_PERSON = 'legal_person',
}

export enum EClientConfigKind {
  SINGLE_USE_LINK = 'single_use_link',
  GEOLOCATION = 'geolocation',
  DIGITAL_SIGNATURE = 'digital_signature',
  AUTOMATIC_REMINDERS = 'automatic_reminders',
  REQUIRED_CERTIFICATE = 'required_certificate',
  ELECTRONIC_SIGNATURE = 'electronic_signature',
  NOTIFICATIONS_BY_ROLE = 'notifications_by_role',
  PROMISSORY_NOTE_TEMPLATE = 'promissory_note_template',
  ELECTRONIC_SIGNATURE_PLUS = 'electronic_signature_plus',
  NAVIGATOR_GEOLOCATION_REQUIRED = 'navigator_geolocation_required'
}

export enum EClientConfigSection {
  SECURITY = 'security',
  NOTIFICATIONS = 'notifications',
  SINGS = 'signs',
}

export enum EClientConfigDataType {
  INT = 'int',
  BOOL = 'bool',
  STRING = 'string',
}