import * as signatureActions from '@literax/modules/documents/store/signature/signature.actions';

import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  ILegalPerson,
  INaturalPerson,
} from '@literax/models/participant.model';
import { Observable, merge } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { asn1, md, pki, util } from 'node-forge';
import { filter, map, take } from 'rxjs/operators';
import {
  selectCertificateErrors,
  selectKeyErrors,
  selectSignDataErrors,
  selectSignatureErrorsForm,
} from '@literax/modules/documents/store/signature/signature.selector';

import { CustomFormValidator } from '@literax/modules/shared/form-lib/custom-form.validator';
import { EDocumentRequest } from '@literax/enums/document.enum';
import { EKindAttachment } from '@literax/enums/attachment.enum';
import { EParticipantKind } from '@literax/enums/participant.enum';
import { I18nToastrService } from '@literax/services/translate/i18n-toastr.service';
import { IAppState } from '@literax/store';
import { ICreateSignatureRequest } from '@literax/models/signature.model';
import { IDocumentResponse } from '@literax/models/document.model';
import { IViewingAttachment } from './../../../models/attachment.model';
import { TranslateService } from '@ngx-translate/core';
import { WorkspaceSelectors } from '@literax/modules/documents/store/workspace/workspace.selectors';
import { getSelectAll } from '@literax/components/configurations/profiles/states/profiles.selector';
import { selectFreeSignatureErrorsForm } from '@literax/store/free-signature/free-signature.selector';

import { uuidv4 } from '../form-lib/uuid';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-sign-document',
  templateUrl: './sign-document.component.html',
  styleUrls: ['./sign-document.component.scss'],
})
export class SignDocumentComponent implements OnInit, OnDestroy {
  currentSigner: ILegalPerson | INaturalPerson | Partial<INaturalPerson>;

  form: FormGroup;
  @Input() showCancel = true;
  @Input() showSubmit = true;
  @Input() document: IDocumentResponse;
  @Input() currentCertName: string;
  @Output() cancelClicked = new EventEmitter();
  @Output() submitClicker = new EventEmitter();
  @ViewChild('fileForm', { static: true })
  fileForm: ElementRef<HTMLFormElement>;
  @Input() requiredCertificated: boolean;
  permittedCertificateFiles = ['.cer'];
  permittedKeyFiles = ['.key'];
  permitedImageFile = ['.jpg', '.jpeg', '.png', '.pdf'];
  identification = false;
  representativeLabel = '';
  moralLabel = 'API.SIGNATURE_TYPES.LEGAL_PERSON';

  attachmentCount: number;

  document$: Observable<IDocumentResponse> = this.store.pipe(
    untilDestroyed(this),
    select(WorkspaceSelectors.selectDocument)
  );
  attachments: IViewingAttachment[];

  certificateAPIErrors$ = this.store.pipe(
    untilDestroyed(this),
    select(selectCertificateErrors)
  );
  signDataAPIErrors$ = this.store.pipe(
    untilDestroyed(this),
    select(selectSignDataErrors)
  );
  keyAndSignDataAPIErrors$ = this.store.pipe(
    untilDestroyed(this),
    select(selectKeyErrors)
  );
  profileInfo$ = this.store.pipe(select(getSelectAll));
  errorsSession$ = this.store.pipe(
    untilDestroyed(this),
    select(selectSignatureErrorsForm)
  );
  errorsFree$ = this.store.pipe(
    untilDestroyed(this),
    select(selectFreeSignatureErrorsForm)
  );
  serverErrors$ = merge(this.errorsFree$, this.errorsSession$);

  isLegalRepresentative = false;

  participants$: Observable<Array<ILegalPerson | INaturalPerson>> =
    this.store.pipe(
      select(WorkspaceSelectors.selectDocumentParticipants),
      map((data) => data.participants)
    );
  legalPersons$: Observable<Array<ILegalPerson>> = this.store.pipe(
    select(WorkspaceSelectors.selectDocumentParticipants),
    map((data) => data.legalPersons)
  );

  private _validateIfTermsAccepted(): boolean {
    return this.form.controls.acceptTerms.status === 'VALID';
  }

  @HostListener('document:keypress', ['$event'])
  onKeyPress(e: KeyboardEvent): void {
    if (
      (e.code === 'enter' || e.charCode === 13 || e.keyCode === 13) &&
      !this._validateIfTermsAccepted()
    ) {
      e.preventDefault();
    }
  }

  constructor(
    private fb: FormBuilder,
    private store: Store<IAppState>,
    private toastr: I18nToastrService,
    translate: TranslateService
  ) {
    this.document$
      .pipe(untilDestroyed(this))
      .subscribe(
        (doc: IDocumentResponse) => (this.attachments = doc?.attachments)
      );
  }

  ngOnInit(): void {
    this.participants$
      .pipe(
        filter((participants) => !!participants),
        take(1)
      )
      .subscribe((participants) => {
        participants.forEach((participant) => {
          if (participant.kind === EParticipantKind.LEGAL_PERSON) {
            participant.legalRepresentatives.forEach((legalRepresentative) => {
              if (legalRepresentative.isOneSelf) {
                this.isLegalRepresentative = true;
                this.identification =
                  participant.idDocumentRequest !==
                  EDocumentRequest.NO_REQUESTED;
                this.currentSigner = legalRepresentative;
              }
            });
          } else {
            if (participant.isOneSelf) {
              this.currentSigner = participant;
              this.identification =
                this.currentSigner.idDocumentRequest !==
                EDocumentRequest.NO_REQUESTED;
            }
          }
        });

        this.form = this.fb.group({
          representative: this.generateForm(this.currentSigner, true),
          acceptTerms: [false, Validators.requiredTrue],
        });
        this.validateSigned();
      });
  }

  validateSigned() {
    if (this.requiredCertificated && this.isLegalRepresentative) {
      this.representativeLabel = 'AUTH.LEGAL_PERSON';
      this.form.addControl('moral', this.generateFormMoral());
    }
    if (this.document && this.document.attachments) {
      this.attachmentCount = this.document.attachments.filter(
        (a) => !a.primary
      ).length;
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(signatureActions.ClearSignatureResult());
  }

  generateFormMoral() {
    const controlArray = this.fb.array([]);
    this.legalPersons$
      .pipe(
        filter((legalPesons) => !!legalPesons),
        take(1)
      )
      .subscribe((legalPesons) => {
        legalPesons.forEach((participant) => {
          participant.legalRepresentatives.map((legalRepresentative) => {
            if (
              legalRepresentative.taxId === this.currentSigner.taxId ||
              legalRepresentative.email === this.currentSigner.email
            ) {
              const form = this.generateForm(legalRepresentative, false);
              controlArray.push(form);
            }
          });
        });
      });
    return controlArray;
  }

  cancel(event: Event) {
    this.cancelClicked.emit(false);
  }

  getPrivateKeyFromFile(keyFile: string, password: string) {
    if (!keyFile || !password || password === '' || keyFile === '') {
      return false;
    }

    const pkeyDer = util.decode64(keyFile);
    const pkeyAsn1 = asn1.fromDer(pkeyDer);

    try {
      const privateKeyInfo = pki.decryptPrivateKeyInfo(pkeyAsn1, password);
      if (!privateKeyInfo) {
        return false;
      }
      return pki.privateKeyFromAsn1(privateKeyInfo);
    } catch (error) {
      return false;
    }
  }

  sign(
    document: string,
    keyFile: string,
    password: string,
    formGroup?
  ): string {
    if (!document || !keyFile) {
      this.toastr.error('TOAST_NOTIFICATIONS.SIGN_MESSAGE_KEY', '');
      return '';
    }

    if (!this.form.get('representative').get('certificate').value) {
      this.toastr.error('TOAST_NOTIFICATIONS.SIGN_MESSAGE_CER', '');
      return '';
    }

    const privateKey = this.getPrivateKeyFromFile(keyFile, password);
    if (privateKey) {
      const messageDigest = md.sha256.create();
      messageDigest.update(document, 'utf8');
      // @ts-ignore
      const signature = privateKey.sign(messageDigest);
      return util.encode64(signature);
    } else if (formGroup) {
      formGroup.get('password').setErrors({ incorrectPassword: true });
      return '';
    } else {
      this.form
        .get('representative')
        .get('password')
        .setErrors({ incorrectPassword: true });
      return '';
    }
  }

  generateSignature() {
    if (!this.form.get('representative').get('password').valid) {
      this.submitClicker.emit(false);
      this.form.controls.password.setErrors({ incorrectPassword: true });
      return false;
    }
    if (this.document.attachments.length < 1) {
      this.submitClicker.emit(false);
      this.form
        .get('representative')
        .get('password')
        .setErrors({ blankDocument: true });
      return false;
    }

    if (!this.form.valid) {
      this.submitClicker.emit(false);
      return false;
    }

    if (this.form.valid) {
      const naturalPerson = this.form.get('representative');
      const legalPersons = this.form.get('moral') as FormArray;
      const naturalPersonPassword = naturalPerson.get('password').value;
      const naturalPersonKey = naturalPerson
        .get('key')
        .value.base64.split(',')[1];

      const legalPersonCerts =
        legalPersons?.controls?.map(
          (legalPerson): string => legalPerson.get('certificate').value.base64
        ) ?? [];

      const legalPersonSignData = (attachment) => ({
        legalPersonSignData:
          legalPersons?.controls?.map((legalPerson) => {
            const doc =
              attachment.kind ===
              (EKindAttachment.MD |
                EKindAttachment.XML |
                EKindAttachment.PROMISSORY_NOTE |
                EKindAttachment.ENDORSEMENT |
                EKindAttachment.PROMISSORY_NOTE_EXTINCTION |
                EKindAttachment.ENDORSEMENT_REVOCATION |
                EKindAttachment.PROMISSORY_NOTE_PRESENTATION_FOR_PAYMENT |
                EKindAttachment.RECEIPT |
                EKindAttachment.CONTRACT |
                EKindAttachment.CONTRACT_ATTACHMENT |
                EKindAttachment.CONTRACT_ANNEX_SUBS |
                EKindAttachment.CONTRACT_ANNEX_SERVICE_UNIQUE)
                ? attachment.filePlainText
                : attachment.base64.replace(
                    /^data:application\/pdf;base64,/,
                    ''
                  );
            return this.sign(
              doc,
              legalPerson.get('key').value.base64.split(',')[1],
              legalPerson.get('password').value,
              legalPerson
            );
          }) ?? [],
      });

      const signatureBody: ICreateSignatureRequest = {
        digitalSignature: {
          naturalPersonCert: naturalPerson.get('certificate').value.base64,
          legalPersonCerts,
          idImage: naturalPerson.get('id_image').value,
          attachmentSignatures: this.attachments.map(
            (attachment: IViewingAttachment) => {
              let doc;
              if (
                [
                  EKindAttachment.MD,
                  EKindAttachment.XML,
                  EKindAttachment.PROMISSORY_NOTE,
                  EKindAttachment.ENDORSEMENT,
                  EKindAttachment.PROMISSORY_NOTE_EXTINCTION,
                  EKindAttachment.ENDORSEMENT_REVOCATION,
                  EKindAttachment.PROMISSORY_NOTE_PRESENTATION_FOR_PAYMENT,
                  EKindAttachment.RECEIPT,
                  EKindAttachment.CONTRACT,
                  EKindAttachment.CONTRACT_ATTACHMENT,
                  EKindAttachment.CONTRACT_ANNEX_SUBS,
                  EKindAttachment.CONTRACT_ANNEX_SERVICE_UNIQUE,
                ].includes(attachment.kind)
              ) {
                doc = attachment.filePlainText;
              } else {
                doc = attachment.base64.replace(
                  /^data:application\/pdf;base64,/,
                  ''
                );
              }
              const signature = this.sign(
                doc,
                naturalPersonKey,
                naturalPersonPassword
              );
              return {
                attachmentId: attachment.id,
                naturalPersonSignData: signature,
                ...legalPersonSignData(attachment),
              };
            }
          ),
        },
      };

      if (
        signatureBody.digitalSignature.attachmentSignatures.some(
          (a) => a.naturalPersonSignData
        )
      ) {
        this.store.dispatch(
          signatureActions.registerSignature({
            payload: {
              documentId: this.document.id,
              signRequestId: this.currentSigner.signRequestId,
              signatureData: signatureBody,
            },
          })
        );
        this.submitClicker.emit(true);
      }
    }
  }

  generateForm(
    signer?: ILegalPerson | INaturalPerson | Partial<INaturalPerson>,
    addId: boolean = false
  ) {
    const form = this.fb.group({
      certificate: new FormControl(null, [
        CustomFormValidator.requiredFileType(...this.permittedCertificateFiles),
        CustomFormValidator.maxFileSize(70),
      ]),
      key: new FormControl(null, [
        Validators.required,
        CustomFormValidator.requiredFileType(...this.permittedKeyFiles),
        CustomFormValidator.maxFileSize(70),
      ]),
      password: new FormControl('', [Validators.required]),
      id: signer.signRequestId,
      resource_uuid: uuidv4(),
      id_image: new FormControl(null),
    });

    if (this.identification && addId) {
      form
        .get('id_image')
        .setValidators([
          Validators.required,
          CustomFormValidator.requiredFileType(...this.permitedImageFile),
          CustomFormValidator.maxFileSize(70),
        ]);
    }
    return form;
  }
}
